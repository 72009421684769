import { StorefrontData } from "./client";

// ====================================================
//                  CART QUERY/MUTATION
// ====================================================

/**
 *
 * @param {*} cartId
 * @returns
 */
export const fetchCart = async (cartId) => {
  const query = `query {
    cart(id: "${cartId}") {
      id
      createdAt
      updatedAt
      lines(first: 250) {
        edges {
          node {
            id
            quantity
            merchandise {
              ... on ProductVariant {
                id
                title
                image {
                  url
                }
                priceV2 {
                  amount
                  currencyCode
                }
                product {
                  title
                }
              }
            }
            sellingPlanAllocation {
              sellingPlan {
                id
                name
              }
              priceAdjustments {
                price {
                  amount
                }
                compareAtPrice {
                  amount
                }
                perDeliveryPrice {
                  amount
                }
              }
            }
          }
        }
      }
      attributes {
        key
        value
      }
      checkoutUrl
      cost {
        totalAmount {
          amount
          currencyCode
        }
        subtotalAmount {
          amount
          currencyCode
        }
        totalTaxAmount {
          amount
          currencyCode
        }
        totalDutyAmount {
          amount
          currencyCode
        }
      }
      buyerIdentity {
        email
        phone
        customer {
          id
        }
        countryCode
      }
    }
  }`;
  const response = await StorefrontData(query);
  return response?.data?.cart;
};

/**
 *
 * @returns
 */
export const createCart = async () => {
  const query = `mutation {
    cartCreate {
        cart {
          id
          createdAt
          updatedAt
          lines(first: 250) {
            edges {
              node {
                id
                quantity
                merchandise {
                  ... on ProductVariant {
                    id
                    title
                    image {
                      url
                    }
                    priceV2 {
                      amount
                      currencyCode
                    }
                    product{
                      title
                    }
                  }
                }
                sellingPlanAllocation {
                  sellingPlan {
                    id
                    name
                  }
                  priceAdjustments {
                    price {
                      amount
                    }
                    compareAtPrice {
                      amount
                    }
                    perDeliveryPrice {
                      amount
                    }
                  }
                }
              }
            }
          }
          cost {
            totalAmount {
              amount
              currencyCode
            }
            subtotalAmount {
              amount
              currencyCode
            }
            totalTaxAmount {
              amount
              currencyCode
            }
            totalDutyAmount {
              amount
              currencyCode
            }
          }
          buyerIdentity {
            email
            phone
            customer {
              id
            }
            countryCode
          }
          attributes {
            key
            value
          }
          checkoutUrl
        }
        userErrors {
          field
          message
        }
      }
    }`;
  const response = await StorefrontData(query);
  return response?.data?.cartCreate?.cart;
};

/**
 *
 * @param {*} cartId
 * @param {*} lineItem
 * @returns
 */
export const addLineItems = async (cartId, lineItem) => {
  const query = lineItem.sellingPlanId
    ? `mutation {
    cartLinesAdd(cartId: "${cartId}" lines: [
      {
        merchandiseId:"${lineItem.merchandiseId}"
        quantity: ${lineItem.quantity}
        sellingPlanId: "${lineItem.sellingPlanId}"
      }
    ]) {
        cart {
          id
          createdAt
          updatedAt
          lines(first: 250) {
            edges {
              node {
                id
                quantity
                merchandise {
                  ... on ProductVariant {
                    id
                    title
                    image {
                      url
                    }
                    priceV2 {
                      amount
                      currencyCode
                    }
                    product{
                      title
                    }
                  }
                }
                sellingPlanAllocation {
                  sellingPlan {
                    id
                    name
                  }
                  priceAdjustments {
                    price {
                      amount
                    }
                    compareAtPrice {
                      amount
                    }
                    perDeliveryPrice {
                      amount
                    }
                  }
                }
              }
            }
          }
          cost {
            totalAmount {
              amount
              currencyCode
            }
            subtotalAmount {
              amount
              currencyCode
            }
            totalTaxAmount {
              amount
              currencyCode
            }
            totalDutyAmount {
              amount
              currencyCode
            }
          }
          buyerIdentity {
            email
            phone
            customer {
              id
            }
            countryCode
          }
          attributes {
            key
            value
          }
          checkoutUrl
        }
        userErrors {
          field
          message
        }
      }
    }`
    : `mutation {
    cartLinesAdd(cartId: "${cartId}" lines: [
      {
        merchandiseId:"${lineItem.merchandiseId}"
        quantity: ${lineItem.quantity}
      }
    ]) {
        cart {
          id
          createdAt
          updatedAt
          lines(first: 250) {
            edges {
              node {
                id
                quantity
                merchandise {
                  ... on ProductVariant {
                    id
                    title
                    image {
                      url
                    }
                    priceV2 {
                      amount
                      currencyCode
                    }
                    product{
                      title
                    }
                  }
                }
                sellingPlanAllocation {
                  sellingPlan {
                    id
                    name
                  }
                  priceAdjustments {
                    price {
                      amount
                    }
                    compareAtPrice {
                      amount
                    }
                    perDeliveryPrice {
                      amount
                    }
                  }
                }
              }
            }
          }
          cost {
            totalAmount {
              amount
              currencyCode
            }
            subtotalAmount {
              amount
              currencyCode
            }
            totalTaxAmount {
              amount
              currencyCode
            }
            totalDutyAmount {
              amount
              currencyCode
            }
          }
          buyerIdentity {
            email
            phone
            customer {
              id
            }
            countryCode
          }
          attributes {
            key
            value
          }
          checkoutUrl
        }
        userErrors {
          field
          message
        }
      }
    }`;
  const response = await StorefrontData(query);
  return response.data.cartLinesAdd.cart;
};

/**
 *
 * @param {*} cartId
 * @param {*} lineItem
 * @returns
 */
export const updateLineItems = async (cartId, lineItem) => {
  const query = lineItem.sellingPlanId
    ? `mutation {
    cartLinesUpdate(cartId: "${cartId}" lines: [
      {
        id: "${lineItem.id}"
        merchandiseId:"${lineItem.merchandiseId}"
        quantity: ${lineItem.quantity}
        sellingPlanId: "${lineItem.sellingPlanId}"
      }
    ]) {
        cart {
          id
          createdAt
          updatedAt
          lines(first: 250) {
            edges {
              node {
                id
                quantity
                merchandise {
                  ... on ProductVariant {
                    id
                    title
                    image {
                      url
                    }
                    priceV2 {
                      amount
                      currencyCode
                    }
                    product{
                      title
                    }
                  }
                }
                sellingPlanAllocation {
                  sellingPlan {
                    id
                    name
                  }
                  priceAdjustments {
                    price {
                      amount
                    }
                    compareAtPrice {
                      amount
                    }
                    perDeliveryPrice {
                      amount
                    }
                  }
                }
              }
            }
          }
          cost {
            totalAmount {
              amount
              currencyCode
            }
            subtotalAmount {
              amount
              currencyCode
            }
            totalTaxAmount {
              amount
              currencyCode
            }
            totalDutyAmount {
              amount
              currencyCode
            }
          }
          buyerIdentity {
            email
            phone
            customer {
              id
            }
            countryCode
          }
          attributes {
            key
            value
          }
          checkoutUrl
        }
        userErrors {
          field
          message
        }
      }
    }`
    : `mutation {
    cartLinesUpdate(cartId: "${cartId}" lines: [
      {
        id: "${lineItem.id}"
        merchandiseId:"${lineItem.merchandiseId}"
        quantity: ${lineItem.quantity}
      }
    ]) {
        cart {
          id
          createdAt
          updatedAt
          lines(first: 250) {
            edges {
              node {
                id
                quantity
                merchandise {
                  ... on ProductVariant {
                    id
                    title
                    image {
                      url
                    }
                    priceV2 {
                      amount
                      currencyCode
                    }
                    product{
                      title
                    }
                  }
                }
                sellingPlanAllocation {
                  sellingPlan {
                    id
                    name
                  }
                  priceAdjustments {
                    price {
                      amount
                    }
                    compareAtPrice {
                      amount
                    }
                    perDeliveryPrice {
                      amount
                    }
                  }
                }
              }
            }
          }
          cost {
            totalAmount {
              amount
              currencyCode
            }
            subtotalAmount {
              amount
              currencyCode
            }
            totalTaxAmount {
              amount
              currencyCode
            }
            totalDutyAmount {
              amount
              currencyCode
            }
          }
          buyerIdentity {
            email
            phone
            customer {
              id
            }
            countryCode
          }
          attributes {
            key
            value
          }
          checkoutUrl
        }
        userErrors {
          field
          message
        }
      }
    }`;
  const response = await StorefrontData(query);
  return response.data.cartLinesUpdate.cart;
};

/**
 *
 * @param {*} cartId
 * @param {*} lineId
 * @returns
 */
export const removeLineItems = async (cartId, lineId) => {
  const query = `mutation {
    cartLinesRemove(cartId: "${cartId}" lineIds: ["${lineId}"]) {
        cart {
          id
          createdAt
          updatedAt
          lines(first: 250) {
            edges {
              node {
                id
                quantity
                merchandise {
                  ... on ProductVariant {
                    id
                    title
                    image {
                      url
                    }
                    priceV2 {
                      amount
                      currencyCode
                    }
                    product{
                      title
                    }
                  }
                }
                sellingPlanAllocation {
                  sellingPlan {
                    id
                    name
                  }
                  priceAdjustments {
                    price {
                      amount
                    }
                    compareAtPrice {
                      amount
                    }
                    perDeliveryPrice {
                      amount
                    }
                  }
                }
              }
            }
          }
          cost {
            totalAmount {
              amount
              currencyCode
            }
            subtotalAmount {
              amount
              currencyCode
            }
            totalTaxAmount {
              amount
              currencyCode
            }
            totalDutyAmount {
              amount
              currencyCode
            }
          }
          buyerIdentity {
            email
            phone
            customer {
              id
            }
            countryCode
          }
          attributes {
            key
            value
          }
          checkoutUrl
        }
        userErrors {
          field
          message
        }
      }
    }`;
  const response = await StorefrontData(query);
  return response.data.cartLinesRemove.cart;
};

export const updateCartAttributes = async (cartId, attribute) => {
  const query = `mutation {
    cartAttributesUpdate(attributes: [{ key: "${attribute.key}", value: "${attribute.value}" }], cartId: "${cartId}") {
      cart {
        id
        createdAt
        updatedAt
        lines(first: 250) {
          edges {
            node {
              id
              quantity
              merchandise {
                ... on ProductVariant {
                  id
                  title
                  image {
                    url
                  }
                  priceV2 {
                    amount
                    currencyCode
                  }
                  product {
                    title
                  }
                }
              }
              sellingPlanAllocation {
                sellingPlan {
                  id
                  name
                }
                priceAdjustments {
                  price {
                    amount
                  }
                  compareAtPrice {
                    amount
                  }
                  perDeliveryPrice {
                    amount
                  }
                }
              }
            }
          }
        }
        cost {
          totalAmount {
            amount
            currencyCode
          }
          subtotalAmount {
            amount
            currencyCode
          }
          totalTaxAmount {
            amount
            currencyCode
          }
          totalDutyAmount {
            amount
            currencyCode
          }
        }
        buyerIdentity {
          email
          phone
          customer {
            id
          }
          countryCode
        }
        attributes {
          key
          value
        }
        checkoutUrl
      }
      userErrors {
        field
        message
      }
    }
  }`;
  const response = await StorefrontData(query);
  return response.data.cartAttributesUpdate.cart;
};

// ====================================================
//                  PRODUCTS QUERY/MUTATION
// ====================================================

/**
 * Get Product Selling Plans
 * @param {*} handle
 * @returns
 */

export const getProductSellingPlans = async (handle) => {
  const query = `query {
      product(handle: "${handle}") {
        requiresSellingPlan
        sellingPlanGroups(first:10) {
          edges {
            node {
              name
              options {
                name
                values
              }
              sellingPlans(first: 10) {
                edges {
                  node {
                    id
                    name
                    description
                    recurringDeliveries
                    options {
                      name
                      value
                    }
                  }
                }
              }
            }
          }
        }
      }
    }`;
  const response = await StorefrontData(query);
  return response.data.product?.sellingPlanGroups.edges;
};

/**
 * Get Related Products
 * @param {*} handle
 * @returns
 */

export const getRelatedProducts = async (productId) => {
  const query = `query {
    productRecommendations(productId: "${productId}") {
      title
      description
      handle
      featuredImage {
        url
        width
        height
      }
      onlineStoreUrl
      id
      metafield(key: "product_placement", namespace: "mix") {
        value
        key
        namespace
      }
      variants(first: 30) {
        edges {
          node {
            id
            title
            availableForSale
            title
          }
        }
      }
      tags
    }
  }`;
  const response = await StorefrontData(query);
  return response.data.productRecommendations;
};
