import React from "react";
import {
  createCart,
  fetchCart,
  addLineItems,
  updateLineItems,
  removeLineItems,
  updateCartAttributes,
} from "../api/query";

const defaultValues = {
  cart: [],
  isOpen: false,
  loading: false,
  addedToCart: false,
  showQuiz: false,
  quizStep: 0,
  changeQuizStep: () => {},
  closeQuiz: () => {},
  onOpen: () => {},
  onClose: () => {},
  addVariantToCart: () => {},
  setAddedToCart: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {},
  getCheckout: () => {},
  updateCustomAttributtes: () => {},
  checkout: {
    lines: {
      edges: [],
    },
  },
};

export const StoreContext = React.createContext(defaultValues);

const isBrowser = typeof window !== `undefined`;
const localStorageKey = `shopify_cart_id`;

export const StoreProvider = ({ children }) => {
  const [checkout, setCheckout] = React.useState(defaultValues.checkout);
  const [loading, setLoading] = React.useState(false);
  const [addedToCart, setAddedToCart] = React.useState(false);
  const [didJustAddToCart, setDidJustAddToCart] = React.useState(false);
  const [showQuiz, setShowQuiz] = React.useState(false);
  const [quizStep, setQuizStep] = React.useState(0);

  const closeQuiz = () => {
    setShowQuiz(false);
  };
  const changeQuizStep = (value) => {
    setQuizStep(value);
  };

  const setCheckoutItem = (checkout) => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout?.id);
    }

    setCheckout(checkout);
  };

  React.useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutID = isBrowser
        ? localStorage.getItem(localStorageKey)
        : null;

      if (existingCheckoutID && existingCheckoutID !== "null") {
        try {
          const existingCheckout = await fetchCart(existingCheckoutID);
          if (existingCheckout) {
            setCheckoutItem(existingCheckout);
            return;
          }
        } catch (e) {
          localStorage.setItem(localStorageKey, null);
        }
      }
      const newCheckout = await createCart();
      setCheckoutItem(newCheckout);
    };

    initializeCheckout();
  }, []);

  /**
   *
   * @param {*} variantId
   * @param {*} quantity
   * @param {*} sellingPlanId
   * @returns
   */

  const addVariantToCart = async (variantId, quantity, sellingPlanId) => {
    setLoading(true);

    const cartID = checkout.id;

    const lineItemsToAdd = sellingPlanId
      ? {
          merchandiseId: variantId,
          quantity: parseInt(quantity, 10),
          sellingPlanId: sellingPlanId,
        }
      : {
          merchandiseId: variantId,
          quantity: parseInt(quantity, 10),
          sellingPlanId: "",
        };
    const response = await addLineItems(cartID, lineItemsToAdd);
    if (response) {
      setCheckout(response);
      setLoading(false);
      setDidJustAddToCart(true);
      setAddedToCart(true);
      setTimeout(() => setDidJustAddToCart(false), 3000);
      return;
    }
    setLoading(false);
  };

  /**
   *
   * @param {*} lineItemID
   * @returns
   */
  const removeLineItem = async (lineItemID) => {
    setLoading(true);

    const cartID = checkout.id;

    const response = await removeLineItems(cartID, lineItemID);
    if (response) {
      setCheckout(response);
      setLoading(false);
      return;
    }
    setLoading(false);
  };

  /**
   * update product quatity
   * @param {*} lineItemID
   * @param {*} quantity
   * @param {*} sellingPlanId
   * @returns
   */
  const updateLineItem = async (
    id,
    lineItemID,
    quantity,
    sellingPlanId = ""
  ) => {
    setLoading(true);

    const cartID = checkout.id;

    const lineItemToUpdate = {
      id: id,
      merchandiseId: lineItemID,
      quantity: parseInt(quantity, 10),
      sellingPlanId: sellingPlanId,
    };
    const response = await updateLineItems(cartID, lineItemToUpdate);
    if (response) {
      setCheckout(response);
      setLoading(false);
      return;
    }
    setLoading(false);
  };

  /**
   *
   * @returns
   */
  const getCheckout = async () => {
    setLoading(true);
    const cartID = checkout.id;
    const response = await fetchCart(cartID);
    if (response) {
      setCheckout(response);
      setLoading(false);
      return;
    }
    setLoading(false);
  };

  /**
   *
   * @param {*} attribute
   * @returns
   */

  const updateCustomAttributtes = async (attribute) => {
    setLoading(true);
    const cartID = checkout.id;
    const response = await updateCartAttributes(cartID, attribute);
    if (response) {
      setCheckout(response);
      setLoading(false);
      return;
    }
    setLoading(false);
  };

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        getCheckout,
        updateCustomAttributtes,
        setAddedToCart,
        checkout,
        loading,
        didJustAddToCart,
        addedToCart,
        showQuiz,
        quizStep,
        closeQuiz,
        changeQuizStep,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
