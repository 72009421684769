exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-cart-jsx": () => import("./../../../src/pages/cart.jsx" /* webpackChunkName: "component---src-pages-cart-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-merch-jsx": () => import("./../../../src/pages/merch.jsx" /* webpackChunkName: "component---src-pages-merch-jsx" */),
  "component---src-pages-moments-jsx": () => import("./../../../src/pages/moments.jsx" /* webpackChunkName: "component---src-pages-moments-jsx" */),
  "component---src-pages-our-process-jsx": () => import("./../../../src/pages/our-process.jsx" /* webpackChunkName: "component---src-pages-our-process-jsx" */),
  "component---src-pages-press-jsx": () => import("./../../../src/pages/press.jsx" /* webpackChunkName: "component---src-pages-press-jsx" */),
  "component---src-pages-products-jsx": () => import("./../../../src/pages/products.jsx" /* webpackChunkName: "component---src-pages-products-jsx" */),
  "component---src-pages-shipping-policy-jsx": () => import("./../../../src/pages/shipping-policy.jsx" /* webpackChunkName: "component---src-pages-shipping-policy-jsx" */),
  "component---src-templates-merch-jsx": () => import("./../../../src/templates/merch.jsx" /* webpackChunkName: "component---src-templates-merch-jsx" */),
  "component---src-templates-press-release-jsx": () => import("./../../../src/templates/pressRelease.jsx" /* webpackChunkName: "component---src-templates-press-release-jsx" */),
  "component---src-templates-product-jsx": () => import("./../../../src/templates/product.jsx" /* webpackChunkName: "component---src-templates-product-jsx" */)
}

